<template>
  <AModalContent
    :show-close="true"
    class="modal-info"
  >
    <div
      class="modal-info-title"
      v-html="title"
    />
    <div
      class="modal-info-text"
      v-html="text"
    />
    <div class="modal-info-actions">
      <button
        class="modal-info-action"
        @click="$emit('close')"
      >
        OK
      </button>
    </div>
  </AModalContent>
</template>
<script>
import { defineComponent } from "vue-demi";
import AModalContent from "../Modal/AModalContent.vue";

export default defineComponent({
    components: { AModalContent },
    props: {
        text: String,
        title: String
    }
})
</script>
<style lang="scss">
.modal-info {
    padding: 15px;
    &-title {
        margin-bottom: 15px;
        font-weight: bold;
    }
    &-text {
    }
}
</style>